$black: rgba(0, 0, 0, 1);
$black-a75: rgba(0, 0, 0, 0.75);
$black-a50: rgba(0, 0, 0, 0.5);
$black-a25: rgba(0, 0, 0, 0.25);
$black-a15: rgba(0, 0, 0, 0.15);
$black-a10: rgba(0, 0, 0, 0.1);
$black-a05: rgba(0, 0, 0, 0.05);

$white-a95: rgba(255, 255, 255, 0.95);
$white-a75: rgba(255, 255, 255, 0.75);
$white-a50: rgba(255, 255, 255, 0.5);
$white-a25: rgba(255, 255, 255, 0.25);
$white-a15: rgba(255, 255, 255, 0.15);
$white-a10: rgba(255, 255, 255, 0.1);
$white-a0: rgba(255, 255, 255, 0);
$white: rgba(255, 255, 255, 1);

$gray-10: #f6f7f7;
$gray-20: #dcdedf;
$gray-30: #cbcecf;
$gray-40: #b8bdbf;
$gray-50: #8b8e8f;
$gray-60: #696b6c;
$gray-70: #2e2f30;
$gray-80: #222324;

$green-10: #b9fbbe;
$green-20: #81f4b2;
$green-30: #48db91;
$green-40: #00ae78;
$green-50: #008762;
$green-60: #00725b;
$green-70: #005d51;
$green-80: #004b4d;

$green-50-a10: rgba(0, 135, 98, 0.1);
$red-50-a10: rgba(218, 47, 28, 0.1);
$yolk-40-a10: rgba(244, 185, 8, 0.1);
$blurple-50-a10: rgba(121, 132, 226, 0.1);

$red-10: #ffe0e1;
$red-20: #ffacad;
$red-30: #fa6657;
$red-40: #e8402f;
$red-50: #da2e1c;
$red-60: #cc2c1c;
$red-70: #851b10;
$red-80: #4d0003;

$orange-10: #ffe3d9;
$orange-20: #ffb48f;
$orange-30: #ff9269;
$orange-35: #e36d00;
$orange-40: #ff7744;
$orange-50: #c85100;
$orange-60: #a33c00;
$orange-70: #802f00;
$orange-80: #591e00;

$yellow-10: #fefce8;
$yellow-20: #fef8d3;
$yellow-70: #61604e;
$yellow-80: #43433c;

$pink-10: #f5e4ff;
$pink-20: #fcd2f8;
$pink-30: #f9b3f5;
$pink-40: #ed73ef;
$pink-50: #b63ebd;
$pink-60: #a821b0;
$pink-70: #970099;
$pink-80: #600061;

$purple: #5a5ae6;
$highlight: #fff29e;
$pure-red: #ff0000;

// Brand colors
// Primary colors
$abstract-black: #191a1b;
$sage: #c6e5df;
$dusty-sage: #c9d5d3;
$creme: #faf1e7;
// Golenrod scale
$gold: #eba52c;
$egg: #fbdfae;
// Oat scale
$oat: #d6a38c;
$birch: #ebd9d0;
// Blueprint scale
$blueprint: #5190d9;
$mist: #dee9f6;

// Brand refresh colors
// After brand refresh goes live, all above colors should be deprecated except:
// Black, White, Gray, Green, Orange, and Pink series
$blurple-10: #f6f6fc;
$blurple-20: #dadbf1;
$blurple-30: #c6c9ef;
$blurple-40: #b3b8ee;
$blurple-45: #818eff;
$blurple-50: #7984e2;
$blurple-60: #4c5fd5;
$blurple-70: #3b4bae;
$blurple-80: #2a3888;
$blurple-90: #192461;

$coffee-10: #fcf5f2;
$coffee-20: #e3dbd5;
$coffee-30: #d7cabc;
$coffee-40: #c9b7a0;
$coffee-50: #998976;
$coffee-60: #77685c;
$coffee-70: #61544a;
$coffee-80: #4c4039;
$coffee-90: #362c27;

$kelp-10: #f4f7f6;
$kelp-20: #d8dedd;
$kelp-30: #c9d5d3;
$kelp-40: #abc0bc;
$kelp-50: #53978e;
$kelp-60: #44736c;
$kelp-70: #335d5c;
$kelp-80: #23484c;
$kelp-90: #12323c;

$yolk-10: #fff5eb;
$yolk-20: #ffe496;
$yolk-30: #ffca31;
$yolk-40: #f4b808;
$yolk-90: #614800;

$cherry-10: #fff3f5;
$cherry-20: #fbd2da;
$cherry-30: #ffb9ce;
$cherry-40: #fa9fb1;
$cherry-50: #f2525a;
$cherry-60: #d21830;
$cherry-70: #ab152a;
$cherry-80: #831224;
$cherry-90: #5c0f1e;

$selected: $blurple-60;
$secondary-selected: $black-a15;
