@import "./colors";
@import "./vars";
@import "./typography";
@import "./theme";

@mixin asset($source) {
  background-image: url("./images/#{$source}.png");
  background-repeat: no-repeat;

  @media (-webkit-min-device-pixel-ratio: 1.3), (min-resolution: 124.8dpi) {
    background-image: url("./images/#{$source}@2x.png");
  }

  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi) {
    background-image: url("./images/#{$source}@3x.png");
  }
}

@mixin breakWord {
  /* cross-browser word-breaking for flex elements */
  overflow-wrap: break-word;
  white-space: normal;
  word-break: break-word;
  word-wrap: break-word;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* similar word-break hack for IE */
    word-break: break-all;
  }
}

@mixin centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin multiline-ellipsis($lines: 2) {
  /*! autoprefixer: off */
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

@mixin flex {
  display: flex;
}

@mixin quick-transition($param: all, $speed: 100ms) {
  transition: $param $speed $super-ease-out;
}

@mixin floating {
  background: $white;

  &::before {
    @include modal-shadow;

    display: block;
    content: $empty;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0.75;
  }
}

@mixin hide-text {
  overflow: hidden;
  text-indent: 150%;
  white-space: nowrap;
}

@mixin modal-shadow {
  $parent: &;

  box-shadow:
    0 0 0 1px $black-a05,
    0 4px 8px $black-a10,
    0 2px 4px $black-a10;

  @at-root {
    @include dark-theme {
      #{$parent} {
        box-shadow:
          0 0 0 1px $white-a15 inset,
          0 0 0 1px $black-a05,
          0 4px 8px $black-a10,
          0 2px 4px $black-a10;
      }
    }
  }
}

@mixin modal-outline {
  box-shadow:
    0 0 0 1px $black-a05,
    0 4px 8px $black-a10,
    0 2px 4px $black-a10,
    0 0 0 1px $black;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    box-shadow: 0 0 0 1px $white-a15 inset;
    border-radius: 6px;
  }
}

@mixin card-outline {
  box-shadow:
    0 0 0 1px $black,
    0 0 0 1px $white-a15 inset;
}

@mixin scrollable {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  transform: translateZ(0); // ensure scrolling does not repaint
  // momentum scrolling on iOS
  -webkit-overflow-scrolling: touch;
}

@mixin selectable {
  user-select: text;
  cursor: text;
}

@mixin sidebar {
  @include border-right;

  flex-grow: 0;
  flex-shrink: 0;
  min-width: $sidebar-min-width;
  max-width: $sidebar-max-width;
  width: 20vw;
}

@mixin sidebar-right {
  @include sidebar;
  @include border-left;

  border-right: 0;
  width: 25vw;
}

@mixin input {
  @include font-m;
  @include input-background;
  @include text-color;
  @include input-box-shadow;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 8px; // 32px height / 4
  padding: 0 $unit;
  width: 100%;

  &::placeholder {
    @include placeholder-text-color;
  }

  &:focus {
    @include box-shadow-focus;

    outline: 0;
  }

  &:disabled,
  &.disabled {
    @include input-background-disabled;
    @include box-shadow;
  }

  @media (max-width: $breakpoint-mobile) {
    @include font-ml;

    &::placeholder {
      line-height: normal;
    }
  }
}

@mixin input-error {
  box-shadow: 0 0 0 1px $red-50;
}

@function circumference($radius) {
  @return 2 * $pi * $radius;
}

/* stylelint-disable scss/at-if-no-null */
@mixin dynamic-padding($only: top right bottom left) {
  $vertical-min-padding: $unit * 2;
  $vertical-padding: $vpadding;
  $vertical-transition: $breakpoint-mobile;
  $horizontal-min-padding: $unit * 2;
  $horizontal-padding: $hpadding;
  $horizontal-transition: $breakpoint-mobile;

  @if index($only, top) != null {
    padding-top: $vertical-min-padding;
  }

  @if index($only, bottom) != null {
    padding-bottom: $vertical-min-padding;
  }

  @if index($only, right) != null {
    padding-right: $horizontal-min-padding;
  }

  @if index($only, left) != null {
    padding-left: $horizontal-min-padding;
  }

  @media (min-width: $vertical-transition) {
    @if index($only, top) != null {
      padding-top: $vertical-padding;
    }

    @if index($only, bottom) != null {
      padding-bottom: $vertical-padding;
    }
  }

  @media (min-width: $horizontal-transition) {
    @if index($only, right) != null {
      padding-right: $horizontal-padding;
    }

    @if index($only, left) != null {
      padding-left: $horizontal-padding;
    }
  }
}
/* stylelint-enable scss/at-if-no-null */

@mixin retina {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}

@mixin non-retina {
  @media (-webkit-min-device-pixel-ratio: 1), (max-resolution: 191dpi) {
    @content;
  }
}

@mixin shimmerBackground() {
  @keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }

  $root: &;

  @at-root {
    #{$root} {
      background-image: linear-gradient(
        to right,
        transparent 0%,
        var(--shimmer-background-color) 20%,
        transparent 40%,
        transparent 100%
      );
    }
  }

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
  background: var(--shimmer-background-color);
  background-repeat: no-repeat;
  background-size: 800px 800px;
}

@mixin virtualized-card-wrapper {
  @include content-background;
  @include border-left;
  @include border-right;
}

@mixin focus-visible {
  &:focus {
    @content (focus);
  }

  &:focus:not(:focus-visible) {
    @content (reset);
  }
}

@mixin inner-keyboard-focus-ring($current-shadow: "") {
  outline: none;

  @if $current-shadow == "" {
    box-shadow: 0 0 0 3px $blurple-30 inset;
    box-shadow: 0 0 0 3px var(--keyboard-focus-color) inset;
  } @else {
    box-shadow:
      $current-shadow,
      0 0 0 3px $blurple-30 inset;
    box-shadow:
      $current-shadow,
      0 0 0 3px var(--keyboard-focus-color) inset;
  }

  &::-moz-focus-inner {
    border: 0;
  }
}

@mixin inner-keyboard-focus-ring-reset {
  box-shadow: unset;
}

@mixin inner-keyboard-focus($current-shadow: "") {
  @include focus-visible using ($slot) {
    @if $slot == focus {
      @include inner-keyboard-focus-ring($current-shadow);
    } @else if $slot == reset {
      @include inner-keyboard-focus-ring-reset;
    }
  }
}

@mixin outer-keyboard-focus-ring($current-shadow: "") {
  outline: none;

  @if $current-shadow == "" {
    box-shadow: 0 0 0 3px $blurple-30;
    box-shadow: 0 0 0 3px var(--keyboard-focus-color);
  } @else {
    box-shadow:
      $current-shadow,
      0 0 0 3px $blurple-30;
    box-shadow:
      $current-shadow,
      0 0 0 3px var(--keyboard-focus-color);
  }

  &::-moz-focus-inner {
    border: 0;
  }
}

@mixin outer-keyboard-focus-ring-reset {
  box-shadow: unset;
}

@mixin outer-keyboard-focus($current-shadow: "") {
  @include focus-visible using ($slot) {
    @if $slot == focus {
      @include outer-keyboard-focus-ring($current-shadow);
    } @else if $slot == reset {
      @include outer-keyboard-focus-ring-reset;
    }
  }
}

// Mimics media queries in web/src/components/Media
@mixin media($screen-size) {
  @if $screen-size == "mobile" {
    @media screen and (max-width: $breakpoint-mobile) {
      @content;
    }
  }

  @if $screen-size == "tablet" {
    @media screen and (max-width: $breakpoint-tablet) {
      @content;
    }
  }

  @if $screen-size == "desktop" {
    @media screen and (min-width: ($breakpoint-mobile + 1)) {
      @content;
    }
  }

  @if $screen-size == "large" {
    @media screen and (min-width: ($breakpoint-tablet + 1)) {
      @content;
    }
  }
}

@mixin jagged-background($color1, $color2) {
  background-image: linear-gradient(
      45deg,
      $color2 25%,
      transparent 25%,
      transparent 75%,
      $color2 75%
    ),
    linear-gradient(135deg, $color2 25%, $color1 25%, $color1 75%, $color2 75%);
}

@mixin jagged-edge($position, $foreground-color, $background-color) {
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: $unit * 1.5;
    background-color: $background-color;
    background-size: ($unit * 2) ($unit * 2);
    background-position: -($unit) ($unit * 0.5);
    background-repeat: repeat-x;

    @if $position == "top" {
      @include jagged-background($foreground-color, $background-color);

      top: 0;
    }

    @if $position == "bottom" {
      @include jagged-background($background-color, $foreground-color);

      bottom: 0;
    }
  }
}

@mixin button-reset($include-keyboard-focus: true) {
  margin: 0;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;

  &:focus {
    outline: 0;
  }

  @if $include-keyboard-focus {
    @include outer-keyboard-focus;
  }
}

@mixin button-color-variant($bg, $bg-hover, $bg-active) {
  color: $white;
  background-color: $bg;

  &:disabled {
    background-color: $bg;
    color: $white-a50;
    cursor: default;
  }

  &:hover:not(:disabled) {
    background-color: $bg-hover;
    color: $white;
  }

  &.active:not(:disabled),
  &:active:not(:disabled) {
    background-color: $bg-active;
    color: $white;
  }
}

@mixin code-block {
  code {
    @include font-mono;
    @include text-color;

    background: $gray-10;
    border: 1px solid $black-a05;
    border-radius: $unit * 0.5;
    padding: 1px 4px;
  }
}

@mixin code-block-dark {
  @include code-block;

  code {
    background: $white-a25;
    color: $white;
  }
}
