@import "../shared/mixins";
@import "../shared/colors";

.icon {
  @include hide-text;

  display: inline-block;
  flex: 0 0 auto;
  width: 24px;
  height: 24px;
  background-size: 24px;
  background-position: center;
  position: relative;
}

.svgIcon {
  width: 24px;
  height: 24px;
  flex: 0 0 auto;
  display: inline-flex;
  pointer-events: none;
}

.svgContainer {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.iconLightGray {
  fill: $gray-40;
}

.iconDanger {
  fill: $red-60;
}

@include dark-theme {
  .iconDanger {
    fill: $red-30;
  }
}

.iconAllGood {
  fill: $green-50;
}

@include dark-theme {
  .iconAllGood {
    fill: $green-30;
  }
}

.iconWarning {
  fill: $orange-50;
}

@include dark-theme {
  .iconWarning {
    fill: $orange-40;
  }
}

.iconCaution {
  fill: $orange-35;
}

.iconTint {
  fill: $blurple-60;
}

@include dark-theme {
  .iconTint {
    fill: $blurple-45;
  }
}

.iconYolk {
  fill: $yolk-40;
}

.iconLight {
  fill: $white;
}

.iconSmall {
  width: 16px;
  height: 16px;
}

.iconMedium {
  width: 32px;
  height: 32px;
}

.iconMedLarge {
  width: 40px;
  height: 40px;
}

.iconLarge {
  width: 48px;
  height: 48px;
}

.iconHuge {
  width: 120px;
  height: 120px;
}

.small {
  width: 16px;
  height: 16px;
}

// Note: special override for icons where fills are preserved
.inheritFill {
  svg {
    fill: inherit;
  }
}

.spinnerIcon {
  text-indent: 0;

  // because the spinner is made up of UL/LI elements
  // the parent line height can effect layout.
  line-height: 24px;

  // small spinner is 16x18px, below centers in 24x24px
  position: absolute;
  top: 3px;
  left: 4px;
}

.disabled {
  opacity: 0.5;
}

.file-artboard,
.file-artboard-light,
.file-artboard-large,
.file-artboard-xd,
.file-artboard-xd-light,
.file-artboard-xd-large,
.file-pasteboard,
.file-pasteboard-light,
.file-pasteboard-large,
.file-color,
.file-color-light,
.file-color-large,
.file-gradient,
.file-gradient-light,
.file-gradient-large,
.file-graphic,
.file-graphic-light,
.file-graphic-large,
.file-slice,
.file-slice-light,
.file-slice-large,
.file-layer,
.file-layer-light,
.file-layer-large,
.file-unknown,
.file-path,
.file-path-light,
.file-path-large,
.file-raster,
.file-raster-light,
.file-raster-large,
.file-folder,
.file-folder-light,
.file-folder-large,
.file-layerstyle,
.file-page,
.file-page-light,
.file-library-page,
.file-library-page-light,
.file-library-symbol,
.file-library-symbol-light,
.file-library-symbol-large,
.file-shape,
.file-shape-light,
.file-shape-large,
.file-symbol,
.file-symbol-dark,
.file-symbol-light,
.file-symbol-large,
.file-text,
.file-text-light,
.file-text-large,
.file-textstyle,
.status-added,
.status-added-light,
.status-removed,
.status-updated,
.status-removed-light,
.status-changed,
.status-changed-light,
.changes-tint,
.changes-light,
.changes,
.symbol-small-light {
  background-color: teal;
}

%file-icon-medium {
  width: 32px;
  height: 32px;
  background-size: 32px;
}

%file-icon-large {
  width: 48px;
  height: 48px;
  background-size: 48px;
}

%file-icon-xl {
  width: 96px;
  height: 96px;
  background-size: 96px;
}

$file-icon-types: sketch illustrator xd;

@each $type in $file-icon-types {
  .file-#{$type},
  .file-#{$type}-light {
    @include asset(file-#{$type}-small);
  }

  .file-#{$type}-medium {
    @include asset(file-#{$type}-medium);

    &.icon {
      @extend %file-icon-medium;
    }
  }

  .file-#{$type}-large {
    @include asset(file-#{$type}-large);

    &.icon {
      @extend %file-icon-large;
    }
  }

  .file-#{$type}-xl {
    @include asset(file-#{$type}-xl);

    &.icon {
      @extend %file-icon-xl;
    }
  }

  .file-#{$type}-library,
  .file-#{$type}-library-light {
    @include asset(file-#{$type}-library);
  }

  .file-#{$type}-library-medium {
    @include asset(file-#{$type}-library-medium);

    &.icon {
      @extend %file-icon-medium;
    }
  }

  .file-#{$type}-library-large {
    @include asset(file-#{$type}-library-large);

    &.icon {
      @extend %file-icon-large;
    }
  }

  .file-#{$type}-library-xl {
    @include asset(file-#{$type}-library-xl);

    &.icon {
      @extend %file-icon-xl;
    }
  }
}

.annotation,
.annotation-add,
.annotation-tint,
.annotation-add-tint,
.api,
.api-light,
.activity,
.arrow-right,
.arrow-left,
.arrow-up,
.arrow-down,
.assets,
.assets-tint,
.attachment,
.back,
.back-tint,
.branch,
.branch-tint,
.branch-light,
.caret-right,
.caret-left,
.caret-up,
.caret-down {
  background: red;
}

.download-tint,
.checkmark,
.checkmark-light,
.checkmark-tint,
.checkmark-green,
.checkmark-small-light,
.clock,
.clock-light,
.clock-tint,
.close,
.close-light,
.close-tint,
.cog,
.cog-tint,
.collection,
.collection-light,
.color,
.color-light,
.color-tint,
.comments-default,
.comments-default-light,
.comments-default-tint,
.comments-new,
.comments-new-light,
.comments-new-tint,
.has-comments,
.commits,
.commits-light,
.commits-tint,
.commit-track-creation,
.commit-track-light .commit-track-commit,
.commit-track-current,
.commit-track-current-light {
  background: blue;
}

.dollar,
.dollar-light,
.download,
.expand,
.expand-light,
.external,
.external-light,
.external-tint,
.danger,
.danger-light,
.danger-tint,
.desktop-notification,
.desktop-notification-tint,
.disclosure,
.disclosure-light,
.disclosure-tint,
.diverged,
.diverged-light,
.diverged-tint,
.drop,
.approve,
.approve-light,
.approve-green,
.approve-small-light,
.request-changes-small-light,
.request-changes-orange {
  background: purple;
}

.files,
.files-light,
.files-tint,
.gift,
.gift-light,
.grid,
.home,
.home-light,
.info,
.info-tint,
.info-light,
.inspect,
.integrations,
.integrations-light,
.layerstyle,
.layerstyle-light,
.layerstyle-tint,
.library,
.library-light,
.library-tint,
.list,
.list-light,
.list-tint,
.markdown,
.markdown-tint,
.master,
.master-light,
.mail,
.mail-light,
.mail-tint,
.mark-as-read,
.mark-as-read-tint,
.merge,
.merge-light,
.merge-tint {
  background: green;
}

.new-default,
.new-default-light,
.new-active-tint,
.new-default-tint,
.new-hover-tint,
.new-inverted-light,
.network-offline,
.network-offline-tint,
.nonvisual,
.nonvisual-light,
.no-image,
.notifications-new,
.notifications-new-light,
.notifications,
.notifications-light,
.organization,
.organization-light,
.organization-small,
.pattern,
.pattern-light,
.pattern-tint,
.plus,
.raster,
.raster-light,
.raster-tint,
.readonly,
.readonly-light,
.readonly-tint,
.revert,
.revert-light,
.revert-tint,
.scissors,
.scissors-light,
.scissors-small,
.scissors-small-light,
.search,
.search-dark,
.slider,
.slider-light,
.status,
.status-light .sync,
.sync-warning {
  background: orange;
}

.share-active,
.share-hover,
.share-light,
.share-tint,
.warning,
.warning-orange,
.warning-light,
.unread-tint,
.update,
.update-light,
.update-tint,
.upgrade,
.upgrade-light,
.upgrade-tint,
.user,
.user-light {
  background: yellow;
}
