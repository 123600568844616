// Wait! before adding another mixin consider using
// an existing mixin from below by adding an argument
// like $property, $before, and $after
// See: `placeholder-text-color`

@mixin light-theme {
  // :not is required so @include light-theme styles won't apply when data-theme is dark
  @media (prefers-color-scheme: light) {
    :root:not([data-theme="dark"]) {
      @content;
    }
  }

  :root[data-theme="light"] {
    @content;
  }
}

@mixin dark-theme {
  // :not is required so @include dark-theme styles won't apply when data-theme is light
  @media (prefers-color-scheme: dark) {
    :root:not([data-theme="light"]) {
      @content;
    }
  }

  :root[data-theme="dark"] {
    @content;
  }
}

// selectors and fallbacks for non css variable supporting browsers
@mixin content-background {
  background: #ffffff;
  background: var(--content-background);
}

@mixin secondary-content-background {
  background: #fafafa;
  background: var(--secondary-content-background);
}

@mixin input-background {
  background: #{$white};
  background: var(--input-background);
}

@mixin input-background-disabled {
  background-color: #353638;
  background-color: var(--input-background-disabled);
}

@mixin input-box-shadow {
  box-shadow: 0 0 0 1px $black-a10;
  box-shadow: 0 0 0 1px var(--input-box-shadow-color);
}

@mixin secondary-selected-background {
  background: rgba(0, 0, 0, 0.15);
  background: var(--secondary-selected-background);
}

@mixin menu-hover-background {
  background: $black-a05;
  background: var(--menu-hover-background-color);
}

@mixin cell-hover-background {
  background: #edeeef;
  background: var(--cell-hover-background);
}

@mixin text-color {
  color: #{$gray-70};
  color: var(--text-color);
}

@mixin secondary-text-color($property: color) {
  #{$property}: #{$gray-60};
  #{$property}: var(--secondary-text-color);
}

@mixin link {
  color: $blurple-60;
  color: var(--link-color);

  &:hover {
    color: $blurple-70;
    color: var(--link-color-hover);
  }

  &:active {
    color: $blurple-80;
    color: var(--link-color-active);
  }
}

@mixin link-inverse {
  color: $blurple-45;
  color: var(--link-color-inverse);

  &:hover {
    color: $blurple-40;
    color: var(--link-color-hover-inverse);
  }

  &:active {
    color: $blurple-30;
    color: var(--link-color-active-inverse);
  }
}

@mixin link-color {
  color: $blurple-60;
  color: var(--link-color);
}

@mixin link-color-hover {
  color: $blurple-70;
  color: var(--link-color-hover);
}

@mixin link-color-active {
  color: $blurple-80;
  color: var(--link-color-active);
}

@mixin error-text-color {
  color: $red-50;
  color: var(--error-text-color);
}

@mixin purple-text-color {
  color: #5a5ae6;
  color: var(--purple-text-color);
}

@mixin allgood-text-color {
  color: $green-50;
  color: var(--allgood-text-color);
}

@mixin placeholder-text-color($property: color) {
  #{$property}: $gray-50;
  #{$property}: var(--placeholder-text-color);
}

@mixin border-color {
  border-color: rgba(0, 0, 0, 0.1);
  border-color: var(--border-color);
}

@mixin border {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border: 1px solid var(--border-color);
}

@mixin box-shadow {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px var(--border-color);
}

@mixin box-shadow-top {
  box-shadow: 0 -1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 -1px var(--border-color);
}

@mixin box-shadow-bottom {
  box-shadow: 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px var(--border-color);
}

@mixin box-shadow-top-bottom {
  box-shadow:
    0 -1px rgba(0, 0, 0, 0.1),
    0 1px rgba(0, 0, 0, 0.1);
  box-shadow:
    0 -1px var(--border-color),
    0 1px var(--border-color);
}

@mixin border-focus {
  border: 1px solid $blurple-60;
  border: 1px solid var(--box-shadow-focus-color);
}

@mixin box-shadow-focus {
  box-shadow: 0 0 0 1px $blurple-60;
  box-shadow: 0 0 0 1px var(--box-shadow-focus-color);
}

@mixin border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid var(--border-color);
}

@mixin border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid var(--border-color);
}

@mixin border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid var(--border-color);
}

@mixin border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid var(--border-color);
}

@mixin card-shadow-l {
  box-shadow: 0 5px 8px $black-a05;
  box-shadow: 0 5px 8px var(--card-shadow-color);
}

@mixin alt-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border: 1px solid var(--secondary-border-color);
}

@mixin alt-border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid var(--secondary-border-color);
}

@mixin alt-border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid var(--secondary-border-color);
}

@mixin inset-border {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 1px var(--border-color) inset;
}

@mixin inset-border-top {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 0 var(--border-color) inset;
}

@mixin inset-border-right {
  box-shadow: -1px 0 0 rgba(0, 0, 0, 0.1) inset;
  box-shadow: -1px 0 0 var(--content-border-color) inset;
}

@mixin inset-border-bottom {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 -1px 0 var(--border-color) inset;
}

@mixin inset-border-left {
  box-shadow: 1px 0 0 rgba(0, 0, 0, 0.1) inset;
  box-shadow: 1px 0 0 var(--border-color) inset;
}

@mixin menu-item-icon-color($property: fill) {
  #{$property}: $black-a50;
  #{$property}: var(--menu-icon-color);
}

@mixin selected-yolk-background {
  background: $yolk-10;
  background: var(--selected-yolk-background);
}
